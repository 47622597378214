@import '../../../../../../../../style/breakpoints';

.add-edit-project-form {
  --ap-gutter-x: 0rem !important;

  padding-top: 0.5rem;

  @include media(desktop) {
    padding-top: 0;
  }
}
