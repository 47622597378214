@import '../../../../style/breakpoints';

.list-item {
  justify-content: space-between;
  width: 100%;
  display: flex;

  .icons {
    display: flex;
    justify-content: flex-end;

    .edit {
      display: flex;

      .edit-txt {
        display: none;

        @include media(desktop) {
          display: block;
          padding-right: 1em;
        }
      }
    }
  }
}
