@import '../../../../style/breakpoints';

.ap-grid.fiscal-years-list {
  --ap-gap: 1rem;

  grid-column: auto/span 12;

  .fiscal-years-list {
    @include media(desktop) {
      grid-column: auto/span 4;
    }
  }

  .ap-container {
    max-width: 100%;

    .ap-buttons-wrapper {
      display: flex;
      justify-content: flex-end;

      .custom + div.ap-button-dropdown {
        width: auto;
      }
    }
  }
}
