@import '../../style/breakpoints';
@import '@appkit4/react-components/styles/scss/variables';

/* stylelint-disable */
$hover-bg-color: rgba(var(--color-background-primary-rgb), var(--opacity-3));
/* stylelint-enable */

.countries-page {
  .ap-list {
    background-color: transparent;
    padding: 0;

    .ap-option-item.active {
      background-color: transparent;
    }

    .rc-virtual-list-holder-inner li {
      background-color: var(--color-background-alt);
      margin: 0.25em 0;
      border-radius: 4px;
      cursor: default;

      .buttons-wrapper {
        display: flex;
        min-width: 150px;
        margin-left: 0.5rem;

        .edit-txt {
          display: none;

          @include media(desktop) {
            display: inline;
          }
        }

        .disabled .ap-option-label {
          cursor: not-allowed;
        }

        .custom + div.ap-button-dropdown {
          width: auto;
        }

        .icon-vertical-more-outline {
          &:hover {
            background-color: $hover-bg-color;
          }
        }

        .ap-option-item {
          &:last-child {
            color: var(--color-text-link-primary);
          }

          &:hover:not(.disabled) {
            background-color: $hover-bg-color;
          }
        }
      }
    }
  }
}
