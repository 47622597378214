body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .relative {
    position: relative;
  }

  .required-dot {
    content: '';
    position: absolute;
    top: var(--spacing-2);
    width: 0.25rem;
    height: 0.25rem;
    border-radius: var(--border-radius-1);
    background-color: var(--color-text-error);
    transition: all 0.3s;
  }

  .hidden {
    display: none;
  }

  .display-inline {
    display: inline;
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0 !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .border-0 {
    border-width: 0 !important;
  }

  .border-1 {
    border: 1px solid;
  }

  .border-left {
    border-left: 2px solid var(--color-background-border);
  }

  .border-right {
    border-right: 1px solid var(--color-background-border);
  }

  .border-bottom-1 {
    border-bottom: 1px solid;
  }

  .pointer {
    cursor: pointer;
  }

  .not-visible {
    visibility: hidden;
  }

  .text-no-wrap {
    white-space: nowrap;
  }

  .text-lowercase {
    text-transform: lowercase;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .text-align-left {
    text-align: left !important;
  }

  .text-align-center {
    text-align: center !important;
  }

  .text-align-right {
    text-align: right !important;
  }

  .font-style-italic {
    font-style: italic !important;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .break-word {
    word-break: break-word;
  }

  .ap-modal-body {
    max-height: 560px;
  }

  .ap-block {
    display: block;
  }

  .ap-inline-block {
    display: inline-block;
  }

  .white-space-prewrap {
    white-space: pre-wrap;
  }

  .icon-not-allowed {
    cursor: not-allowed !important;
    color: var(--color-text-disabled) !important;
  }

  .textearea-double-height {
    textarea {
      height: 12.5rem !important;
    }
  }

  .ap-buttons-wrapper {
    .ap-button-dropdown {
      width: unset;
    }
  }
}
