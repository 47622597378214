.info-box {
  border-radius: 8px;
  padding: 0.8rem;
  font-size: 1rem;

  &.flex {
    display: flex;
  }

  a {
    color: var(--color-text-link-primary);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
