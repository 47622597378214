.tabs {
  display: flex;

  .tabber {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: var(--spacing-4);
  }

  .button {
    margin-left: var(--spacing-4);
  }
}
