.dropdown-with-additional-checkboxes {
  overflow-x: hidden !important;
  max-height: 250px !important;

  .ap-option-group {
    min-height: 40px;
    background-color: transparent;
  }

  .ap-option-item {
    margin-left: var(--spacing-3);
    background-color: var(--color-background-selected);
  }
}
