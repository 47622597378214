.default-modal {
  .ap-modal-footer-customize {
    width: 100%;
  }
}

.default-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-without-required {
  justify-content: flex-end;
}
