@import '@appkit4/react-components/styles/scss/variables';

.country-editor-leader-list {
  .ap-panel .ap-panel-body {
    padding: 0.75rem;
  }

  .user-card {
    border: 1px solid var(--color-background-border);
    border-radius: 4px;
    margin-bottom: 0.5rem;

    .justify-content-between {
      justify-content: space-between;
    }

    .flex-column {
      flex-direction: column;
    }

    .align-self-center {
      align-items: center;
    }
  }
}
