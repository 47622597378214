.additional-information-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  .url {
    word-break: break-all;
    font-style: italic !important;
  }
}
