@import '../../../../../../style/breakpoints';

.discount-progress-bar {
  :global(.ap-progress-mark) {
    display: none;
  }

  :global(.ap-progress) {
    width: 100% !important;
  }

  @include media(desktop) {
    :global(.ap-progress) {
      margin-bottom: 0 !important;
    }
  }
}

.input-number-wrapper {
  margin-bottom: var(--spacing-7);

  :global(.ap-field-title-container) {
    width: calc(100% - 40px);
  }

  @include media(desktop) {
    margin-bottom: 0 !important;
  }
}
