@import '../../../../../../style/breakpoints';

.email-test-section-wrapper {
  margin-bottom: 3rem;

  .email-test-section {
    max-width: 760px;
    margin: 0 auto;

    .send-test-email-btn {
      width: 100%;
      margin-top: 0.75rem;
      @include media(desktop) {
        margin-top: 0.25rem;
      }
    }
  }
}
