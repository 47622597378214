.questionnaire-countries-page {
  .footer-container {
    justify-content: space-between;
  }

  .label {
    line-height: 15px !important;
    font-size: 0.75rem;
    text-align: center;
  }

  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .border-left {
    border-left: 2px solid var(--color-background-border);
  }

  .unanswered-questions {
    span {
      border: 1px solid var(--color-background-border);
      border-radius: 1.5rem;
      font-size: 0.875rem !important;
    }
  }
}
