.pricing {
  margin-top: var(--spacing-4);

  .content {
    margin-top: var(--spacing-4);
  }

  .action-single-button-width {
    width: 70px;
  }

  .action-buttons-width {
    width: 215px;
  }

  .action-single-edit-button-width {
    width: 160px;
  }

  .no-buttons {
    width: 0;
  }
}
