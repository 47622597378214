.export-result {
  border-radius: 0.25rem !important;
  border: 2px solid var(--color-background-container-alt);
  background: var(--color-background-container-alt) !important;
  padding: 0.5rem 0 0 !important;
  margin-bottom: 1.25rem;

  .ap-panel-body {
    padding: 0.5rem 0.5rem 0.25rem !important;
  }

  .radio-wrapper {
    width: calc(100% - 0.75rem);

    .ap-radio-container {
      width: inherit;

      .ap-radio {
        margin-top: 4px;
      }

      .ap-radio-label {
        width: inherit;
      }
    }
  }
}

.document-with-image-card-panel {
  min-width: 260px;
}
