.changed-values-container {
  .ap-table {
    box-shadow: unset;
    padding-left: 0;
    padding-right: 0;

    tbody {
      tr {
        &:first-child {
          td {
            border: 0;
          }
        }
      }
    }
  }

  .ap-accordion {
    .ap-accordion-content {
      padding-right: var(--spacing-4);
    }
  }
}
