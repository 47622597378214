.results-list-item {
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);

  &:global(.border-bottom-1:last-child) {
    border-bottom-width: 0;
  }
}

.optional-scope {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}
