@import '../../../../style/breakpoints';

.results-tab {
  .results-tab-header {
    .export-btn {
      width: 100%;
    }

    @include media(desktop) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .export-btn {
        width: auto;
      }
    }

    .ap-btn-group {
      button {
        width: 100%;
        margin-bottom: var(--spacing-5);

        &.group-right {
          width: 40px;
        }

        @include media(desktop) {
          width: unset;
          margin-bottom: 0;
        }
      }
    }
  }
}
