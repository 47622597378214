.custom-countries-modal {
  .ap-panel-body {
    .ap-panel-content {
      span {
        word-break: break-word;
        white-space: normal;
      }
    }
  }
}
