.country-contact-info-modal {
  margin: 1rem;

  :global(.ap-container) {
    --ap-gutter-x: 0rem !important;
  }

  .country-snapshot-contact {
    margin-top: 0.5rem;

    :global(.ap-panel-body) {
      padding: 0.5rem 0 !important;
    }

    :global(.ap-panel-bordered) {
      box-shadow: none !important;
    }
  }
}
