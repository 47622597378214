@import '../../../style/breakpoints';

.ap-progress {
  &.fullsize {
    width: 100% !important;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }

  &.fullsize.is-horizontal.ap-progress-readonly ul {
    width: calc(100%) !important;

    li {
      div {
        transform: translateX(0%) !important;
        text-align: end;
      }
    }

    li:nth-of-type(2) {
      div {
        transform: translateX(0%) !important;
        text-align: center;
      }
    }

    @include media(desktop) {
      li:last-of-type {
        div {
          transform: translateX(50%) !important;
        }
      }
    }
  }
}

.ap-progress-mark {
  top: 0.5rem;
}
