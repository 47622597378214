.results-tab-table-container {
  .ap-table {
    box-shadow: unset;
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
  }

  .url,
  .opentext {
    color: var(--color-text-light);
  }

  .url {
    word-break: break-all;
    font-style: italic !important;
  }

  .results-tab-table-countries {
    table {
      tbody {
        tr {
          &:first-child {
            td {
              border: 0;
            }
          }
        }
      }
    }
  }
}
