.cookie-policy-approval-modal {
  :global(.ap-modal-footer-customize) {
    width: 100%;
  }

  :global(.default-modal-footer) {
    width: 100%;
  }

  :global(.ap-flex) {
    width: 100%;
    flex-wrap: wrap;
  }

  :global(.ap-button:not([disabled]).ap-button-primary) {
    width: 100%;
  }
}
