.local-file-result {
  :global(.ap-table table thead th:not(:last-child) .header-container) {
    margin-right: 0;
  }

  :global(.ap-table table thead th:nth-child(7) .header-container) {
    padding: 0;
  }

  :global(.ap-table table thead th:nth-child(2)),
  :global(.ap-table table thead th:nth-child(3)),
  :global(.ap-table table thead th:nth-child(5)),
  :global(.ap-table table thead th:nth-child(6)) {
    width: 110px;
  }

  .row-width {
    width: 50px;
  }

  .column-width {
    width: 150px;
    text-align: start;

    &:first-child {
      padding-left: 12px;
    }
  }

  .year-count-1 {
    width: 150px;
  }

  .year-count-2 {
    width: 300px;
  }

  .year-count-3 {
    width: 450px;
  }
}
