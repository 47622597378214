@import '@appkit4/react-components/styles/scss/variables';

.custom-badge {
  margin: 0.25rem 0;
  width: fit-content;

  &.ap-badge.ap-badge-info-outlined {
    background-color: $neutral-01;
    box-shadow: none;
    border: 1px solid $neutral-05;
    word-break: break-word;

    .ap-badge-text {
      line-height: 1rem;
    }
  }
}
