@import '../../../../style/breakpoints';

.subcategories-list {
  .list {
    .list-item {
      padding: var(--spacing-5) var(--spacing-4);

      &:not(:last-child) {
        border-bottom: 2px solid var(--color-background-border);
      }
    }
  }

  .add-subcategory {
    button {
      width: 100%;
    }

    @include media(desktop) {
      display: flex;
      justify-content: flex-end;

      button {
        width: unset;
      }
    }
  }
}
