.project-tools-container-mfe {
  min-width: 100% !important;
}

.project-tools {
  .ap-tabset-container {
    overflow-x: auto;

    .ap-tabset-toggle-wrapper {
      border-bottom: 0;
    }
  }

  .ap-panel-body {
    padding: 0.25rem;

    .ap-tabset-toggle-wrapper.filled {
      background-color: transparent;
    }
  }

  .ap-panel-footer {
    padding: 0 0.5rem;
  }
}

.warning-top-panel {
  height: 48.8px;
}
