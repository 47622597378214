.form-card {
  padding: 1rem;
  border-radius: 0.5rem;

  .display-inline {
    display: inline;
  }

  .required-dot {
    &.title {
      top: 0;
      right: -0.75rem;
    }
  }
}
