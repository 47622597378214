.categories-list {
  justify-content: space-between;
  width: 100%;

  .ap-accordion-toggle {
    background-color: var(--color-background-alt);
    padding: 1rem;

    .ap-accordion-title-extra {
      order: 1;
      margin-left: 1em;
      margin-right: 0 !important;
    }
  }

  .ap-accordion-content {
    padding: 0 !important;
  }
}
