$desktop-width: 1024px;

@mixin media($keys...) {
  @each $key in $keys {
    @if $key == desktop {
      @media (min-width: #{$desktop-width}) {
        @content;
      }
    } @else {
      @content;
    }
  }
}
