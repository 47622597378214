.panel {
  overflow: hidden;

  .image {
    border: solid 1px var(--color-background-border);
    background-color: #f3f3f3 !important;
    overflow: hidden;

    & > img {
      object-fit: cover;
      width: 100%;
      overflow: visible;
    }
  }
}
