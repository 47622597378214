.questionnaires-questions-table-container {
  .ap-table {
    box-shadow: unset;
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
  }

  .url {
    word-break: break-all;
    font-style: italic !important;
  }

  table {
    thead {
      th {
        &:last-child {
          width: 50px;
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          td {
            border: 0;
          }
        }
      }
    }
  }
}
