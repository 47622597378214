@import '../../../../style/breakpoints';

.question-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .question-item-arrows {
    display: flex;
    flex-direction: column;

    // align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .question-text {
    display: flex;
    align-items: center;
    word-break: break-word;
    overflow: hidden;
  }

  .manage-question {
    display: flex;
    cursor: pointer;

    @include media(desktop) {
      min-width: 6em;
    }

    .edit-question {
      display: flex;

      .edit-text {
        display: none;

        @include media(desktop) {
          display: block;
        }
      }
    }
  }
}
