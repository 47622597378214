.total-card {
  :global(.row) {
    --ap-gutter-x: 0rem !important;
  }
}

.dotted-border {
  border: 1px dotted var(--color-background-primary);
  border-radius: var(--border-radius-2);
}
