@import '../../../style//breakpoints.scss';

$suffix-width: 50px;

.suffix-icon {
  .ap-field-suffix-wrapper {
    width: 2.5rem;
  }

  .ap-field-container .ap-field-title-container {
    max-width: calc(100% - $suffix-width);
  }
}
