.accordion-wit-checkbox {
  .ap-accordion {
    .ap-accordion-toggle {
      .ap-accordion-title {
        height: unset !important;
      }
    }

    &:hover {
      background-color: var(--color-background-alt);
    }
  }

  span {
    word-break: break-word;
    white-space: normal;
  }
}
