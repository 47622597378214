.table-separated-by-lines {
  table {
    box-shadow: unset;
    table-layout: fixed;

    thead {
      th {
        display: table-cell;

        &:first-child {
          div {
            padding-left: 0;
          }
        }

        span {
          font-weight: var(--font-weight-1);
          color: var(--color-text-light);
        }
      }
    }

    tbody {
      tr {
        td {
          display: table-cell;
          border-top: 1px solid var(--color-background-border);
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}
