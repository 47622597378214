@import '../../../../style/breakpoints';

.regions-page {
  .regions-list {
    .ap-accordion-toggle {
      background-color: var(--color-background-alt);
      padding: 1rem;
    }

    .accordion-content {
      max-width: unset;

      .add-region-btn {
        width: 100%;

        @include media(desktop) {
          width: unset;
          margin-right: 0;
          margin-left: auto;
        }
      }

      .region-details-container {
        &:not(:last-child) {
          border-bottom: 2px solid;
        }

        .icon {
          text-align: end;
        }

        .edit-txt {
          display: none;

          @include media(desktop) {
            display: inline;
          }
        }
      }
    }
  }
}
