.ap-loading {
  background-color: rgb(25 25 25 / 0%);
  transition: background-color 1s;
  transition-delay: background-color 0.3s;

  &.overlay {
    width: 100vw;
    height: 100vh;
    inset: 0;
    position: fixed;
    display: flex;
    outline: 0;
    z-index: 3000;
    background-color: rgb(25 25 25 / var(--opacity-5));
    cursor: no-drop;
    backdrop-filter: blur(2px);
    overflow: auto;
    opacity: 1;
    transition: all 1s;
    transition-delay: 1s;

    &.modal {
      width: auto;
      height: auto;
      position: absolute;
      background-color: rgb(25 25 25 / var(--opacity-4));
    }
  }
}
