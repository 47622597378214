@import '../../../../style/breakpoints';

.countries-snapshot-page-header {
  display: flex;

  @include media(desktop) {
    justify-content: flex-end;
  }

  .add-btn {
    width: 100%;

    @include media(desktop) {
      width: unset;
    }
  }
}
