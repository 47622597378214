.loading-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-message {
    .row {
      justify-content: center;
    }
  }
}
