.single-or-multiple-choice-container {
  .row {
    align-items: center;

    .single-or-multiple-choice-input {
      width: 90% !important;
    }

    .single-or-multiple-choice-delete-btn {
      width: 10% !important;
    }
  }

  .add-option-btn {
    padding-left: 0;
  }
}
