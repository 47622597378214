.ap-panel {
  .ap-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

    .right-divider {
      box-shadow: var(--color-background-border) 1px 0 0;
    }

    .ap-buttons-wrapper {
      display: flex;
      justify-content: flex-end;

      .custom + div.ap-button-dropdown {
        width: auto;
      }
    }
  }

  .ap-panel-body {
    padding: 1.25rem;
  }

  .warning-icon {
    color: var(--color-text-warning);
  }
}
