@import '../../style/breakpoints';

$mobile-icon-size: 32px;
$desktop-icon-size: 60px;

.navigation-tile-grid {
  .single-navigation-tile-card {
    --ap-gap: 1rem;

    grid-column: auto/span 12;

    @include media(desktop) {
      grid-column: auto/span 4;
    }

    .ap-panel-body {
      padding: 1.5rem 1rem;
      height: 100%;

      .ap-panel-content {
        height: 100%;

        .navigation-tile-sections {
          height: 100%;

          .ap-container {
            max-width: 100% !important;
            height: 100%;

            .row.navigation-tile-section {
              align-items: center;
              height: 100%;

              @include media(desktop) {
                &.navigation-tile-section {
                  flex-direction: column;
                }
              }

              &.col {
                @include media(desktop) {
                  padding-top: 1rem;
                }
              }

              .col-icon {
                flex: 0;

                @include media(desktop) {
                  margin-bottom: 1rem;
                }

                span {
                  font-size: $mobile-icon-size;
                  line-height: $mobile-icon-size;

                  @include media(desktop) {
                    font-size: $desktop-icon-size;
                    line-height: $desktop-icon-size;
                  }
                }
              }

              .col-informations {
                @include media(desktop) {
                  padding: 0;
                }
              }

              .col-button {
                flex: 0 1;
                padding: 0;

                .button {
                  margin-left: auto;
                  padding-right: 0;
                }
              }
            }

            .tile-with-additional-info {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .icon-and-name {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .col-icon {
                  padding-left: 0 !important;
                }

                .col-informations {
                  padding-left: calc(var(--ap-gutter-x) * 0.5);
                  display: flex;
                  align-items: center;

                  @include media(desktop) {
                    padding-left: 0 !important;
                  }
                }

                @include media(desktop) {
                  flex-direction: column;
                }
              }

              .info-and-chevron {
                display: flex;
                flex-direction: row;
                align-items: center;

                @include media(desktop) {
                  flex-direction: column;
                  align-items: end;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }
}
