.breadcrumbs-with-text {
  span {
    word-break: break-word;
    white-space: normal;
  }

  .ap-breadcrumb-item {
    .ap-breadcrumb-separator {
      cursor: default;
    }

    span {
      cursor: default;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
