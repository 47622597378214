.gate-questions {
  .ap-accordion.country-group {
    background-color: var(--color-background-alt) !important;

    .ap-accordion-title {
      color: var(--text-heading, #252525);
      margin: 0.5rem;
      font: var(--typography-subheading) !important;
      font-weight: var(--font-weight-2);
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.4px;
    }

    .ap-accordion-content {
      padding: 0;
      margin: 1rem;
      margin-top: 0;
      padding-bottom: 0.5rem;

      .ap-accordion.question-group {
        border: solid 1px var(--color-background-default);
        border-radius: var(--border-radius-3) !important;
        margin-bottom: var(--spacing-4);

        .ap-accordion-toggle {
          padding: 0.5rem;

          .ap-accordion-title {
            font: var(--typography-body-s) !important;
            font-weight: var(--font-weight-2);
            color: dimgray !important;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .ap-accordion-content {
          padding: 0;
          margin-top: 0;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
