@import '@appkit4/react-components/styles/scss/variables';

.document-list-item {
  border-radius: 0.25rem;
  background-color: $neutral-01;
  padding: 1.25rem 1rem;
  margin-top: 0.5rem;

  &.ap-list-item {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 0.25rem 0;
  }

  &.ap-list-item:hover {
    background-color: $neutral-01;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .flex {
      display: flex;
    }
  }
}
