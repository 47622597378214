@import '../../../../../../style/breakpoints';

.answer-group {
  display: flex;
  flex-direction: column;

  @include media(desktop) {
    & {
      flex-direction: row;
      justify-content: space-between;

      & > div:first-child {
        margin-right: var(--spacing-4) !important;
      }
    }
  }
}
