.project-categories-list-container {
  .ap-accordion .ap-accordion-content {
    padding-right: var(--spacing-4);
  }

  .project-subcategories-list-container {
    ul {
      border-radius: 4px;
      border: 1px solid var(--color-background-border);
      margin: 0.5em 0;

      .project-question {
        border: 1px solid var(--color-background-border);
        display: flex;
      }
    }
  }
}
