.loading-modal {
  .flex-column {
    flex-direction: column;

    span {
      text-align: center;
    }
  }

  .ap-modal-header-icons-container,
  .ap-modal-footer {
    display: none;
  }
}
