.questions-list {
  background-color: unset !important;
  padding: 0 !important;

  .questions-list-item {
    cursor: default;
    background-color: var(--color-background-container);
    border-radius: 4px;
  }
}
