@import '../../../../../../style/breakpoints';

.button-group {
  display: flex;
  justify-content: space-between;

  @include media(desktop) {
    & {
      padding: var(--spacing-4) !important;
      flex-direction: column-reverse;
      justify-content: flex-end;

      button {
        margin-bottom: var(--spacing-4) !important;
      }
    }
  }
}
