@import '../../../../style/breakpoints';

.breadcrumb {
  .breadcrumb-desktop {
    display: none;
  }

  .breadcrumb-mobile {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5em;

    .icon {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
  }

  .button-component-mobile {
    width: 100%;
  }

  @include media(desktop) {
    .breadcrumb-mobile,
    .button-component-mobile {
      display: none;
    }

    .breadcrumb-desktop {
      display: block;

      .breadcrumb-desktop-title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
