.local-file-card {
  max-width: 50% !important;

  :global(.ap-panel-body) {
    padding: 1rem 0;
  }
}

.local-file-card:first-of-type {
  margin-left: var(--spacing-4);
}
