@import '../../../../../../style/breakpoints';

$max-width-of-container: 430px;

.add-question-form {
  .add-question-container {
    flex-direction: column;

    @include media(desktop) {
      flex-direction: row;

      .col {
        max-width: $max-width-of-container;
      }
    }
  }
}
