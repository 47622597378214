@import '../../../../style/breakpoints';

.results-by-question {
  .ap-accordion {
    border: 1px solid var(--color-background-border);

    @include media(desktop) {
      border: 0;
    }
  }
}
