$border: 1px solid var(--color-background-border);
$radius: var(--border-radius-2);

.legal-statements-approval-modal {
  .ap-modal-header-close {
    display: none;
  }

  .legal-statements-approval {
    .document-content {
      overflow-y: auto;
    }

    .desktop-view {
      .ap-tabset-container {
        overflow-x: auto;
      }

      .document-content {
        max-height: 370px;
      }
    }

    .mobile-view {
      .ap-accordion-content {
        padding: var(--spacing-3);
      }

      .document-content {
        max-height: 270px;
      }
    }
  }
}
