@import '@appkit4/react-components/styles/scss/variables';

.sdk-radio {
  &.flex {
    display: flex;
  }

  .ap-radio-container {
    padding-left: 0.75rem;

    .ap-radio-label {
      height: unset;
      padding-left: 0 !important;
    }
  }

  .with-divider {
    height: 4rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $neutral-05;

    .ap-radio-label {
      padding-left: 0.5rem !important;
    }
  }

  .with-divider:last-of-type {
    border-bottom-width: 0;
  }

  .regions-section {
    .ap-accordion .ap-accordion-content {
      padding: 0 var(--spacing-4);
    }
  }
}
