@import '@appkit4/react-components/styles/scss/variables';
@import '../../../../../../style/breakpoints';

.edit-email-template-form {
  max-width: 760px;
  margin: 0 auto;

  .wrapper {
    width: 100%;
    margin-top: 0.5rem;
    @include media(desktop) {
      margin-top: 0.25rem;
    }
  }

  .show-more-btn {
    margin: 0 auto;
  }
}
