.inputs {
  :global(.ap-tabset-toggle-wrapper.filled) {
    background-color: transparent !important;
  }

  :global(.tabber .ap-tabset-toggle-wrapper) {
    border-bottom: none;
  }
}

.reports-dropdown {
  :global(.ap-option-item) {
    &:global(.active) {
      background-color: transparent;

      :global(.ap-option-label) {
        font-weight: normal !important;
      }
    }
  }
}
