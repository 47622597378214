.project-card {
  &.desktop {
    .secondary-column {
      min-width: 820px;
    }
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
