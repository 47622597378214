.empty-state {
  &.page {
    margin: 3rem auto;
    opacity: 0.3;
    text-align: center;
    width: 100%;
  }

  &.accordion-select {
    width: auto;
    padding: 1rem;
    margin: 0;
    opacity: 0.3;
    text-align: center;
  }
}
