.power-bi-action-modal {
  width: 1140px !important;

  .power-bi-content {
    --ap-gutter-x: 0rem !important;

    max-width: 100% !important;

    &.is-mobile {
      .sdk-radio > div:nth-child(2n + 3) {
        padding-left: 0.75rem;
      }
    }
  }

  .pbi-radio-button {
    .sdk-radio .ap-radio-container {
      padding-left: 0;
      margin-left: 0 !important;
    }
  }

  .flex-column {
    flex-direction: column;
  }
}
