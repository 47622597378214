@import '../../style/breakpoints';
@import '@appkit4/react-components/styles/scss/variables';

$header-height: 64px;
$navigation-width: 280px;
$navigation-closed-width: 72px;
$footer-height: 40px;
$space-around-content: 32px;

.ap-header {
  min-width: unset !important;
  position: fixed !important;

  .ap-header-title .title,
  .ap-header-logo {
    display: none;
  }

  .app-type {
    margin-left: 1rem;
    color: var(--color-text-body);
    font-weight: 700;
    font-size: smaller;
  }

  .search-icon {
    margin-left: 1rem;
    cursor: pointer;
  }

  @include media(desktop) {
    .ap-header-title {
      border-left: solid 1px var(--color-background-border);
      margin-left: 8px;
      padding-left: 8px;

      .icon {
        display: none;
      }

      .title {
        display: inline;
      }
    }

    .ap-header-logo {
      display: block;
    }
  }
}

.layout {
  display: flex;
  padding-top: $header-height;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  &__page-form {
    max-width: 760px;
    margin: 0 auto;
  }

  .ap-navigation-wrapper {
    height: calc(100vh - $header-height) !important;
    display: none;
    transition: width 0.2s linear;

    @include media(desktop) {
      display: block;
      position: unset;
      position: absolute;
      z-index: 98;
    }

    .ap-navigation-footer {
      display: none;
    }

    &:hover {
      width: $navigation-width;

      .ap-navigation-item {
        white-space: nowrap;
      }
    }
  }

  @include media(desktop) {
    .ap-navigation-heading {
      display: none !important;
    }
  }

  .ap-navigation-content {
    position: fixed;
    width: $navigation-width !important;
    background-color: var(--color-background-alt) !important;
    transition: width 0.2s linear;

    .selected {
      font-weight: normal !important;
      background-color: transparent !important;
    }

    .nav-item-child .selected-item .ap-navigation-item,
    .selected-item .ap-navigation-item:not(.nav-item-child .ap-navigation-item) {
      font-weight: var(--font-weight-2) !important;
      background-color: var(--color-background-hover-selected) !important;
    }
  }

  .ap-navigation-wrapper:hover .ap-navigation-content {
    width: $navigation-width;
  }

  .nav-open {
    .ap-navigation-wrapper {
      display: block;
      height: 100vh !important;
      z-index: 99;

      .ap-navigation-content {
        padding-top: 0.5rem !important;
        width: $navigation-width !important;
      }

      .ap-navigation-heading {
        padding-top: 1.5rem;

        .ap-navigation-title-wrapper {
          width: 100%;
          border-bottom: 1px solid $color-background-border;
          padding-bottom: 1rem;
        }
      }
    }
  }

  @include media(desktop) {
    .nav-open {
      background-color: unset;
      overflow: initial;
      position: unset;
      z-index: 98;

      .ap-navigation-wrapper {
        height: calc(100vh - $header-height) !important;
      }
    }

    .ap-navigation-content {
      padding-top: 1em !important;
    }

    .collapse-nav {
      .ap-navigation-content {
        width: $navigation-closed-width !important;
      }
    }
  }

  .layout-content-wrapper {
    width: 100vw;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media(desktop) {
      padding-left: 100px;
    }

    .layout-content {
      padding-top: $space-around-content;

      .expandable-button {
        width: 100%;
      }

      @include media(desktop) {
        .expandable-button {
          width: unset;
        }
      }
    }
  }

  .footer {
    min-height: $footer-height;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;

    a {
      color: var(--color-text-link-primary);
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
