$form-card-max-width: 760px;

.add-answer-form {
  label,
  span {
    word-break: break-word;
  }

  .sdk-radio .ap-radio-container {
    padding-left: 0;
  }

  .italic {
    font-style: italic !important;
  }

  .form-card {
    max-width: $form-card-max-width;
  }
}
