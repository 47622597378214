@import '../../../../style/breakpoints';

.tara-results {
  :global(.ap-btn-group) {
    :global(.ap-button) {
      width: 100%;
      margin-bottom: var(--spacing-5);

      &:global(.group-right) {
        width: 40px;
      }

      @include media(desktop) {
        width: unset;
        margin-bottom: 0;
      }
    }
  }
}

.tara-results-dropdown {
  :global(.ap-option-item) {
    &:global(.active) {
      background-color: transparent;

      :global(.ap-option-label) {
        font-weight: normal !important;
      }
    }
  }
}
