.notification-wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2100;
  margin: 0 auto;

  .ap-notification {
    word-break: break-word;
  }
}
