.card-footer {
  margin-top: 12px;
  display: flex;

  .text-button {
    .ap-link {
      text-decoration: none !important;
    }
  }
}
