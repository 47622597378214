@import '@appkit4/react-components/styles/scss/variables';

.rich-text-editor {
  border: solid 1px $neutral-05;
  border-radius: 0.25rem;

  &.ck-editor {
    .ck-toolbar {
      height: 4.5rem !important;
      border-radius: var(--border-radius-3) var(--border-radius-3) 0 0 !important;
      background-color: var(--color-background-container) !important;
      padding: var(--spacing-4) var(--spacing-4) var(--spacing-6) !important;
      border: none !important;
    }
  }
}
