@import '@appkit4/react-components/styles/scss/variables';

.contact-info {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .add-btn {
      padding: 0 var(--spacing-3);
    }
  }

  .country-editors-list,
  .country-leaders-list {
    .ap-panel .ap-panel-body {
      padding: 0.75rem;
    }

    .user-card {
      border: 1px solid var(--color-background-border);
      border-radius: 4px;
      margin-bottom: 0.5rem;
    }
  }
}
