@import '../../../../style/breakpoints';

.project-results-accordion {
  .ap-accordion .ap-accordion-toggle .ap-accordion-title {
    height: 100%;
  }

  .ap-accordion {
    background-color: var(--color-background-alt);

    .ap-accordion-content {
      padding-right: var(--spacing-4);

      @include media(desktop) {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &:hover {
      background-color: var(--color-background-alt);
    }
  }
}
