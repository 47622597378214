@import '@appkit4/react-components/styles/scss/variables';

.regions-accordion {
  .ap-accordion-title {
    order: 1;
    padding-left: 0.5rem;
  }

  .ap-accordion-content {
    padding: 0 !important;
  }

  .ap-accordion {
    &.border {
      .ap-accordion-toggle {
        border: 1px solid $neutral-05;
      }
    }
  }
}
