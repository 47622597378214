.ap-panel.fiscal-year-card {
  .ap-container {
    padding-left: 0;
    padding-right: 0;
  }

  .ap-panel-body {
    padding: 1.25rem;
  }

  .ap-panel-footer {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;

    .text-button {
      .ap-link {
        text-decoration: none !important;
      }
    }
  }
}
