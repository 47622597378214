.search-row {
  margin-top: calc(-1 * var(--ap-gutter-y));
  margin-right: calc(-0.5 * var(--ap-gutter-x));
  margin-left: calc(-0.5 * var(--ap-gutter-x));
  display: flex;

  & > * {
    padding-right: calc(var(--ap-gutter-x) * 0.25);
    padding-left: calc(var(--ap-gutter-x) * 0.25);
    margin-top: var(--ap-gutter-y);
  }

  .search-input {
    padding-left: calc(var(--ap-gutter-x) * 0.5);
    width: initial;
    max-width: 1000px;
    flex-grow: 1;
  }

  .search-button {
    padding-right: calc(var(--ap-gutter-x) * 0.5);
    width: initial;
  }
}
